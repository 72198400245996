<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>            
                  <h5>Medical Record</h5>
                </label>
                <input
                  v-model="form.note"
                  type="text"
                  class="form-control"
                  name="note"
                  placeholder="Input your note here"
                  :class="{ 'is-invalid': $v.form.note.$error }"
                />
                <div v-if="$v.form.note.$error" class="invalid-feedback">
                  <span v-if="!$v.form.note.required">This value is required.</span>
                </div>
              </div>
              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="notes.length">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div id="cd-timeline">
              <ul class="timeline list-unstyled">
                <li class="timeline-list" v-for="(note, index) in notes" :key="index">
                  <div class="cd-timeline-content p-4">
                    <h5 class="mt-0 mb-3">{{ moment(note.dateAt).format('DD MMMM YYYY') }} by {{ note.staff }}
                      <button class="btn btn-secondary btn-sm float-right" @click="edit(note)">Edit</button>
                    </h5>
                    <p class="mb-0">
                      {{ note.note }}
                    </p>
                    <div class="date bg-primary">
                      <h4 class="mt-0">{{ note.dateAt | tanggal}}</h4>
                      <p class="mb-0 text-white-50">{{ note.dateAt | bulan}}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-edit-record"
      title="Edit Medical Record"
      size="md"
      title-class="text-black font-18"
      hide-footer
      no-close-on-backdrop
      @close="$emit('close')"
    >
      <div class="row">
        <div class="col-12">
          <form @submit.prevent="editRecord"> 
            <div class="form-group">
              <label>Date</label>
              <br />
              <date-picker
                  v-model="input.date"
                  format="DD MMMM YYYY"
                  name="date"
                  :first-day-of-week="1"
                  lang="en"
                  placeholder="Select One"
                  value-type="YYYY-MM-DD"
                  :disabled-date="notAfterToday"
                ></date-picker>
              </div> 
            <div class="form-group">
              <label>Notes</label>
              <input
                v-model="input.note"
                class="form-control"
                step="1"
                min="1"
                required
              />
            </div>
            <button type="submit" class="btn btn-primary">Save</button>
          </form>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import moment from 'moment'

DatePicker.methods.displayPopup = function () {
  this.position = {
    left: 0,
    top: "100%",
  };
};

import {
  required
} from "vuelidate/lib/validators";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Medical Record",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, DatePicker },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/home"
        },
        {
          text: "User",
          href: "/user"
        },
        {
          text: "Medical Record",
          active: true
        }
      ],
      user: {
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
      },
      form: {
        note: null
      },
      notes: [],
      isError: false,
      errorMessage: null,
      input: {
        note: null,
        date: null
      },
      moment,
    };
  },
  validations: {
    form: {
      note: { required }
    }
  },
  computed: {
    title() {
      return `${this.user.firstname} ${this.user.lastname} Medical Records`
    }
  },
  mounted() {
    this.fetchUser()
    this.fetchNotes()
  },
  methods: {
    fetchUser() {
      this.$axios.get(`/user/${this.$route.params.id}`)
        .then( (response) => { 
          const result = response.data.result

          let phoneNumber = null
          if (result.user.phone.toString().substring(0,3) == "+62") {
            phoneNumber = result.user.phone.toString()
          } else if(result.user.phone.toString().substring(0,2) == "62"){ 
            phoneNumber = `+${result.user.phone.toString()}`
          } else if(result.user.phone.toString().charAt(0) == "0"){ 
            phoneNumber = `+62${result.user.phone.toString().substring(1)}`
          } else {
            phoneNumber = `${result.user.phone.toString()}`
          }

          this.user = {
            firstname: result.user.firstname.charAt(0).toUpperCase() + result.user.firstname.slice(1),
            lastname: result.user.lastname.charAt(0).toUpperCase() + result.user.lastname.slice(1),
            phone: phoneNumber.substring(3),
            email: result.user.email,
          }

        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    },
    fetchNotes() { 
      this.$axios.get(`user/progress-note/history/${this.$route.params.id}`, {
        params: {
          page: 1
        }
      }).then(response => {
        this.notes = response.data.result.notes
      })
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let param = new FormData();
        param.append('note', this.form.note) 
        param.append('user', this.$route.params.id)

        this.$axios.post('/user/progress-note/add', param)
        .then( (result) => { 
          console.log(result.data)
          Swal.fire("Success", "Progress Note Created Successfully", "success");
          this.fetchNotes()
          this.form.note = null
          this.$nextTick(() => { this.$v.$reset() })
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },
    editRecord() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
        let param = new FormData();
        param.append('note', this.input.note) 
        param.append('date', this.input.date)
        param.append('user', this.$route.params.id)

        this.$axios.post(`/user/progress-note/${this.input.noteId}`, param)
        .then( (result) => { 
          console.log(result.data)
          Swal.fire("Success", "Progress Note Updated Successfully", "success");
          this.fetchNotes()
          this.form.note = null
          this.$nextTick(() => { this.$v.$reset() })
          this.$bvModal.hide('modal-edit-record')
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      // }
    },
    edit(note) {
      this.input = {
        ...note,
        date: this.moment(note.dateAt).format('YYYY-MM-DD')
      };
      this.$bvModal.show('modal-edit-record');
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  }
};
</script>